/* eslint-disable */
import React from "react";
import { Outlet} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {LinkContainer} from 'react-router-bootstrap'
import logo from '../assets/images/logo.png';

const ResponsiveNavbar = () => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <>
      <Navbar bg="light" expand="lg" expanded={expanded}>
        <Container>
          <Navbar.Brand href="/"><img
              src={logo}
              alt="Pub Golf Masters"
              height="30px"
              className="p-10"
              style={{ paddingLeft: '10px' }}
            /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link onClick={() => setExpanded(false)}>Scorecard</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/bingo">
                <Nav.Link onClick={() => setExpanded(false)}>Bingo</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/leaderboard">
                <Nav.Link onClick={() => setExpanded(false)}>Leaderboard</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/rules">
                <Nav.Link onClick={() => setExpanded(false)}>Rules</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/bingorules">
                <Nav.Link onClick={() => setExpanded(false)}>Bingo Rules</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};

export default ResponsiveNavbar;
