import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import "./App.css";
import { UserProvider } from "./contexts/User/User";
import PubGolf from "./PubGolf";
import { NotificationContainer } from "react-notifications";

function App() {
  return (
    <div className="App background">
      <UserProvider>
        <PubGolf />
      </UserProvider>
      <NotificationContainer/>

      <p className="text-centre" style={{ fontSize: 10 }}>
        {" "}
        © Church of Urch{" "}
      </p>
    </div>
  );
}

export default App;
