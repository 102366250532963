/* eslint-disable */
import React from "react";
import logo from '../assets/images/logo.png';
import { Container } from "react-bootstrap";

const LoadingAnimation = () => {
  return (
    <Container fluid className="loading-container">
          <img src={logo} alt="Loading" className="pulsing-image" style={{marginTop:"-150px"}} />
    </Container>
  );
};

export default LoadingAnimation;