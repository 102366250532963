/* eslint-disable */
import "../App.css";
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { UserContext } from "../contexts/User/User";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Collapse } from "react-bootstrap";
import { calculateBingoScore } from "../utils/Bingo";
import { NotificationManager } from "react-notifications";
import LoadingAnimation from "../components/LoadingAnimation";
import { Link } from "react-router-dom";
import BingoRules from "./BingoRules";

const ADMIN_ID = "aVidi38mKdt9tHKPuiA5";

const BingoCard = ({ user, showSpinner = false }) => {
  const [currentUser] = React.useContext(UserContext);
  const [officials, setOfficials] = React.useState([]);
  const [challenges, setChallenges] = React.useState([]);
  const [groupedChallenges, setGroupedChallenges] = React.useState([]);
  const [bingoScore, setBingoScore] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [otherChallengesOpen, setOtherChallengesOpen] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);

    const fetchPost = async () => {
      //get officials
      const officialsQuery = query(
        collection(db, "scorecard", user.scorecard, "players"),
        where("official", "==", true)
      );
      const officialsSnapshot = await getDocs(officialsQuery);
      const officialsData = officialsSnapshot.docs.map((player) => player.id);
      setOfficials(officialsData);
      //get user challenges
      const bingoSnapshot = await getDocs(
        collection(db, "scorecard", user.scorecard, "players", user.id, "bingo")
      );
      const rawChallenges = bingoSnapshot.docs.map((challenge) => {
        return { id: challenge.id, ...challenge.data() };
      });

      const bingoData = orderBingoChallenges(rawChallenges);
      setChallenges(bingoData);
      setBingoScore(calculateBingoScore(bingoData));

      // get challenges not on users bingo card
      const allBingoSnapshot = await getDocs(
        collection(db, "scorecard", user.scorecard, "bingo")
      );
      const rawAllChallenges = allBingoSnapshot.docs.map((challenge) => {
        return { id: challenge.id, ...challenge.data() };
      });

      const playerChallengeIds = new Set(bingoData.map((item) => item.id));
      const otherChallenges = rawAllChallenges.filter(
        (item) => !playerChallengeIds.has(item.id)
      );

      setGroupedChallenges(
        groupChallengesByWeight(sortBingoChallenges(otherChallenges))
      );
      setLoading(false);
    };

    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    const bingoScore = calculateBingoScore(challenges);
    (async function () {
      await setDoc(
        doc(db, "scorecard", user.scorecard, "players", user.id),
        { bingoScore },
        {
          merge: true,
        }
      );
    })();
    setBingoScore(bingoScore);
  }, [challenges]);

  const handleChallengeClick = async (challenge_id) => {
    showError("Game has ended"); return;

    if (!officials.includes(currentUser.id)) {
      showError("Only officials can mark bingo challenges");
      return;
    }

    if (user.id === currentUser.id) {
      showError("You can't verify yourself mate");
      return;
    }

    const challenge = challenges.find((c) => c.id === challenge_id);
    if (!challenge) {
      console.log("No Challenge");
      return;
    }

    challenge.completed = !challenge.completed;
    (challenge.verifiedBy = challenge.completed ? currentUser.name : null),
      await setDoc(
        doc(
          db,
          "scorecard",
          user.scorecard,
          "players",
          user.id,
          "bingo",
          challenge.id
        ),
        {
          completed: challenge.completed,
          verifiedBy: challenge.completed ? currentUser.name : null,
        },
        {
          merge: true,
        }
      );

    setChallenges((prev) =>
      prev.map((el) => (el.id === challenge.id ? challenge : el))
    );
  };

  const groupChallengesByWeight = (challenges) => {
    // Step 1: Group challenges by weight, combining weights 3 and 4
    return challenges?.reduce((groups, challenge) => {
      const { weight } = challenge;

      // Group weights 3 and 4 together under a new key, e.g., '3-4'
      const groupKey = weight === 3 || weight === 4 ? "3-4" : weight;

      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }
      groups[groupKey].push(challenge);
      return groups;
    }, {});
  };

  const sortBingoChallenges = (challenges) => {
    return challenges.sort((x, y) => x.number - y.number);
  };

  const orderBingoChallenges = (challenges) => {
    return challenges.sort((x, y) => x.orderNumber - y.orderNumber);
  };

  //TODO score
  const formatBingoScore = (score) => {
    return score;
  };

  const showError = (message) => {
    NotificationManager.error(message, null, 3000);
  };

  return (
    <Container>
      {loading && !showSpinner && <LoadingAnimation />}
      {loading && showSpinner && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {!loading && (
        <>
            <Row>
          {!showSpinner && (
              <Col className="col-6">
                <Link to="/">
                  <Button className="btn-lg w-100 m-top" variant="primary">
                    Scorecard
                  </Button>
                </Link>
              </Col>
          )}

              <Col className={showSpinner ? "col-12" : "col-6"}>
                <Button
                  className="btn-lg w-100 m-top"
                  onClick={() => setOtherChallengesOpen(!otherChallengesOpen)}
                  aria-controls="example-collapse-text"
                  aria-expanded={otherChallengesOpen}
                  variant="success"
                >
                  Extra Challenges
                </Button>
              </Col>
            </Row>
          <Row className="m2"></Row>
          <Collapse in={otherChallengesOpen}>
            <Container>
              <Card className="m15">
                <Card.Body>
                  <p>
                    These are the challenges available for "Extra Challenge" on
                    your scorecard. They are from the bingo challenge pool, and
                    are not on your bingo card.
                  </p>
                  <div>
                    {Object.keys(groupedChallenges).map((groupKey, index) => (
                      <div key={index}>
                        <h3>
                          {groupKey === "3-4"
                            ? "Extra Challenges #3 and #4"
                            : `Extra Challenge #${groupKey}`}
                        </h3>
                        {groupedChallenges[groupKey].map((challenge) => (
                          <div key={challenge.id}>
                            <b>#{challenge.number}:</b> {challenge.name}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                  <Button
                    className="btn-lg w-100 m-top"
                    onClick={() => setOtherChallengesOpen(!otherChallengesOpen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={otherChallengesOpen}
                    variant="success"
                  >
                    {otherChallengesOpen ? "Hide" : "Show"} Extra Challenges
                  </Button>
                </Card.Body>
              </Card>
            </Container>
          </Collapse>
          <div className="m1 shadow">
            <Card>
              <Row className="m2 text-centre bg-light-grey">
                <Col>
                  <h4>{user.name}</h4>
                </Col>
                <Col>
                  <h4>
                    Score:
                    <br />
                    {formatBingoScore(bingoScore)}
                  </h4>
                </Col>
              </Row>
            </Card>
          </div>
          <Container fluid className="button-grid-container">
            <div className="grid-container">
              {challenges.map((challenge, index) => (
                <Button
                  key={index}
                  className="grid-button"
                  onClick={() => handleChallengeClick(challenge.id)}
                  variant={challenge.completed ? "success" : "secondary"}
                >
                  <div className="button-content">
                    <span className="button-top-text">
                      #{challenge.number}
                      {challenge.verifiedBy && (
                        <>
                          <br /> Verified: {challenge.verifiedBy}
                        </>
                      )}
                    </span>
                    <span className="button-top-text">{challenge.name}</span>
                  </div>
                </Button>
              ))}
            </div>
          </Container>
          <BingoRules/>
        </>
      )}
    </Container>
  );
};

export default BingoCard;
