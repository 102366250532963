/* eslint-disable */
import "../App.css";
import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import trophy from "../assets/images/trophy.png";
import group from "../assets/images/group.jpg";

const Notice = () => {
  return (
    <>
      <Row className="m2 text-centre">
        <h4>The score is in!</h4>
        <p>Congratulations to our Pub Golf Masters 2024 winners:</p>
      </Row>
      <Row className="m2 text-centre">
        <Col>
          <img src={trophy} style={{ width: 100 }} />
          <h3>Ben Elton</h3>
        </Col>
        <Col>
          <img src={trophy} style={{ width: 100 }} />
          <h3>Frida Ostgren</h3>
        </Col>
      </Row>
      <Row className="m2 text-centre">
        <p>Thanks to everyone who played! Special thanks to Karlo Zanki for organising this year's scorecard.</p>
        <h3>See you next year!</h3>
        <img src={group} />
      </Row>
    </>
  );
};

export default Notice;
