// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBvD8PY2RScG6VSpsxa0kk4xNtToFIgSh8",
  authDomain: "pubgolf-84017.firebaseapp.com",
  projectId: "pubgolf-84017",
  storageBucket: "pubgolf-84017.appspot.com",
  messagingSenderId: "847901198078",
  appId: "1:847901198078:web:8196a9f24693a7da6c47af"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);