// contexts/User/reducer.js

export const reducer = (state, player) => {

    return {
        ...state,
        scorecard: 'XmoseJSkoUqagXn5uzQn',
        name: player?.name,
        id: player?.id,
        game: player?.game
    }
}

export const initialState = {
    name: localStorage.getItem("name"),
    id: localStorage.getItem("player_id"),
    game: localStorage.getItem("game"),
    scorecard: 'XmoseJSkoUqagXn5uzQn'
}