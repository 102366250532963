/* eslint-disable */
import React, { useState } from "react";
// import { UserContext } from "./../contexts/User/User";

//Bootstrap
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Venue = ({
  venue,
  user,
  merits,
  handleChallengeClick,
  handleMeritClick,
  formatScore,
}) => {
  const {
    id,
    name,
    number,
    arrive,
    score,
    info = "",
    challenges,
    completedChallenge,
    completedMerits,
  } = venue;
  // const [currentUser, dispatch] = React.useContext(UserContext);
  const [meritsOpen, setMeritsOpen] = useState(false);

  const scoreName = {
    2: "Double Bogey +2",
    1: "Bogey +1",
    0: "Par 0",
    "-1": "Birdie -1",
    "-2": "Eagle -2",
    "-3": "Albatross -3",
    "-4": "Condor -4",
  };

  return (
    <Card>
      <div className="bottom-border textured-background">
        {/* <Row className="m2 m-top text-centre">
          <Col>
            <h3>{name}</h3>
          </Col>
        </Row> */}
        <Row className="m2 text-centre">
          <Col className="col-2 pt-3 right-border">
            <h2>#{number}</h2>
          </Col>
          <Col className="col-8 pt-1">
            <h3>{name}</h3>
            <Card.Title>{arrive}</Card.Title>
          </Col>

          <Col className="col-2 pt-3 left-border">
            <h2>{formatScore(score)}</h2>
          </Col>
        </Row>
      </div>
      <Card.Body className="text-centre bg-light-grey">
        <Row className="m2">
          <Card.Title>Challenges</Card.Title>
          {challenges?.map((challenge) => (
            // <Col>
            <Button
              className="button"
              onClick={() => handleChallengeClick(id, challenge.id)}
              key={challenge.id}
              variant={
                completedChallenge === challenge.id ? "success" : "secondary"
              }
            >
              <u>{scoreName[challenge.score] || challenge.score}</u> <br />{" "}
              {challenge.name}
            </Button>
            // </Col>
          ))}
        </Row>
        {info && (
          <Row>
            <h6>{info}</h6>
          </Row>
        )}
        {completedMerits.length > 0 && (
          <Row className="m2 m-top">
            <Card.Title>Claimed De/Merits</Card.Title>
            {merits?.map(
              (merit) =>
                completedMerits?.includes(merit.id) && (
                  <Button
                    onClick={() => handleMeritClick(id, merit.id)}
                    key={`${id}_${merit.id}_button`}
                    variant={
                      completedMerits?.includes(merit.id)
                        ? "success"
                        : "secondary"
                    }
                  >
                    {merit.name} <br />
                    {formatScore(merit.score)}
                  </Button>
                )
            )}
          </Row>
        )}
        <Row className="m2 m-top">
          <Card.Title>
            {completedMerits.length > 0 && "Unclaimed"} De/Merits
          </Card.Title>
          <Button
            onClick={() => setMeritsOpen(!meritsOpen)}
            aria-controls="example-collapse-text"
            aria-expanded={meritsOpen}
          >
            {meritsOpen ? "Hide" : "Show"}
          </Button>
        </Row>
        <Collapse in={meritsOpen}>
          <div>
            {merits?.map(
              (merit) =>
                !completedMerits?.includes(merit.id) && (
                  <Row className="m2">
                    <Button
                      onClick={() => handleMeritClick(id, merit.id)}
                      key={`${id}_${merit.id}_button`}
                      variant={
                        completedMerits?.includes(merit.id)
                          ? "success"
                          : "secondary"
                      }
                      className="me-3"
                    >
                      {merit.name} <br />
                      {formatScore(merit.score)}
                    </Button>
                  </Row>
                )
            )}
          </div>
        </Collapse>
      </Card.Body>
    </Card>
  );
};

export default Venue;
