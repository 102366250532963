/* eslint-disable */
import React from 'react';
import ResponsiveNavBar from './components/ResponsiveNavBar';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Scorecard from './pages/Scorecard';
import BingoCard from './pages/BingoCard';
import Leaderboard from './pages/Leaderboard';
import Rules from './pages/Rules';
import NoPage from './pages/NoPage';
import { UserContext } from './contexts/User/User';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import BingoRules from './pages/BingoRules';


const PubGolf = () => {
    const [user] = React.useContext(UserContext)
    return (
        <BrowserRouter>
            <Routes>
            <Route path="/" element={<ResponsiveNavBar />}>
                {/* <Route index element={
                    <ProtectedRoute user={user}>
                        <Scorecard user={user}/>
                    </ProtectedRoute>
                } />
                <Route path="bingo" element={
                    <ProtectedRoute user={user}>
                        <BingoCard user={user}/>
                    </ProtectedRoute>
                } />
                <Route path="leaderboard" element={
                    <ProtectedRoute user={user}>
                        <Leaderboard user={user}/>
                    </ProtectedRoute>
                } />
                <Route path="rules" element={
                    <ProtectedRoute user={user}>
                        <Rules/>
                    </ProtectedRoute>
                } />
                <Route path="bingorules" element={
                    <ProtectedRoute user={user}>
                        <BingoRules/>
                    </ProtectedRoute>
                } />
                <Route path="login" element={
                    <UnprotectedRoute user={user}>
                        <Login />
                    </UnprotectedRoute>
                } /> */}
                <Route path="*" element={<Leaderboard user={user} />} />
                <Route index element={<Leaderboard user={user} />} />
                {/* <Route path="/logout" element={<Logout />} /> */}
                {/* <Route path="*" element={<NoPage />} /> */}
            </Route>
            </Routes>
        </BrowserRouter>
    );
}

const ProtectedRoute = ({ user, children }) => {
    if (!user.id || user.game !== "bingo" ) {
      return <Navigate to="/login" replace />;
    }


    return children;
};

const UnprotectedRoute = ({ user, children }) => {
    if (user.id && user.game === "bingo") {
      return <Navigate to="/" replace />;
    }
    return children;
};


export default PubGolf;