/* eslint-disable */
import "../App.css";
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { UserContext } from "./../contexts/User/User";
import { collection, doc, getDocs, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import Venue from "../components/Venue";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { NotificationManager } from "react-notifications";
import LoadingAnimation from "../components/LoadingAnimation";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const ADMIN_ID = "aVidi38mKdt9tHKPuiA5";

const Scorecard = ({ user, showSpinner = false }) => {
  const [currentUser] = React.useContext(UserContext);
  const [venues, setVenues] = React.useState([]);
  const [merits, setMerits] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [holesComplete, setHolesComplete] = React.useState(0);
  const [totalScore, setTotalScore] = React.useState(0);

  React.useEffect(() => {
    setLoading(true);

    const fetchPost = async () => {
      const venuesSnapshot = await getDocs(
        collection(db, "scorecard", user.scorecard, "venues")
      );
      const rawVenues = venuesSnapshot.docs.map((venue) => {
        return { id: venue.id, ...venue.data() };
      });

      const meritsSnapshot = await getDocs(
        collection(db, "scorecard", user.scorecard, "merits")
      );
      const rawMerits = meritsSnapshot.docs.map((merit) => {
        return { id: merit.id, ...merit.data() };
      });

      const venuesData = await Promise.all(
        rawVenues.map(async (venue) => {
          let newVenue = { ...venue };
          const challengeSnapshot = await getDocs(
            collection(
              db,
              "scorecard",
              user.scorecard,
              "venues",
              venue.id,
              "challenges"
            )
          );
          const venueChallengeData = challengeSnapshot.docs.map((challenge) => {
            return { id: challenge.id, ...challenge.data() };
          });
          newVenue.challenges = sortChallenges(venueChallengeData);

          const playerVenueSnapshot = await getDoc(
            doc(
              db,
              "scorecard",
              user.scorecard,
              "players",
              user.id,
              "venues",
              venue.id
            )
          );
          const playerVenueData = playerVenueSnapshot.data();
          newVenue.completedChallenge = playerVenueData?.challenge;
          newVenue.completedMerits = playerVenueData?.merits || [];
          newVenue.score = calculateVenueScore(newVenue, rawMerits);

          return newVenue;
        })
      );

      setHolesComplete(calculateHolesComplete(venues));
      setTotalScore(calculateTotalScores(venues));

      setVenues(sortVenues(venuesData));
      setMerits(sortMerits(rawMerits));
      setLoading(false);
    };

    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    const golfScore = calculateTotalScores(venues);
    const holesComplete = calculateHolesComplete(venues);
    (async function () {
      await setDoc(
        doc(db, "scorecard", user.scorecard, "players", user.id),
        { golfScore, holesComplete },
        {
          merge: true,
        }
      );
    })();
    setHolesComplete(holesComplete);
    setTotalScore(golfScore);
  }, [venues]);

  const handleChallengeClick = async (venue_id, challenge_id) => {
    showError("Game has ended"); return;

    if (currentUser.id !== user.id && currentUser.id !== ADMIN_ID) return;
    if (
      currentUser.id === ADMIN_ID &&
      !window.confirm("Are you sure my guy?")
    ) {
      return;
    }
    const venue = venues.find((v) => v.id === venue_id);
    if (!venue) {
      console.log("No Venue");
      return;
    }

    let currentChallenge =
      venue.completedChallenge === challenge_id ? null : challenge_id;
    await setDoc(
      doc(
        db,
        "scorecard",
        user.scorecard,
        "players",
        user.id,
        "venues",
        venue.id
      ),
      {
        challenge: currentChallenge,
      },
      {
        merge: true,
      }
    );
    venue.completedChallenge = currentChallenge;
    venue.score = calculateVenueScore(venue);

    setVenues((prev) => prev.map((el) => (el.id === venue.id ? venue : el)));
  };

  const handleMeritClick = async (venue_id, merit_id) => {
    showError("Game has ended"); return;

    if (currentUser.id !== user.id && currentUser.id !== ADMIN_ID) return;
    if (
      currentUser.id === ADMIN_ID &&
      !window.confirm("Are you sure my guy?")
    ) {
      return;
    }
    const venue = venues.find((v) => v.id === venue_id);
    if (!venue) {
      showError("can't find venue");
      return;
    }
    const merit = merits.find((m) => m.id === merit_id);
    if (!merit) {
      showError("Can't find merit");
      return;
    }

    let localMerits = [...venue.completedMerits];
    if (localMerits.includes(merit_id)) {
      //remove from array
      localMerits = localMerits.filter(function (e) {
        return e !== merit_id;
      });
    } else {
      //check if merit can only be claimed once
      if (merit.single) {
        const claimedVenue = venues.find((v) =>
          v.completedMerits.includes(merit_id)
        );
        if (claimedVenue) {
          showError(
            `Already claimed at ${claimedVenue.name}. Can only claim once.`
          );
          return;
        }
      }

      //check if merit can only be claimed at specific pubs
      if (merit.venues && !merit.venues.includes(venue_id)) {
        const claimableAt = venues.filter((v) => merit.venues.includes(v.id));
        showError(
          `Can only be claimed at ${claimableAt.map((v) => v.name).join(", ")}`
        );
        return;
      }

      //check if it is exclusive of any other merit, remove other
      if (localMerits.includes(merit.exclusive)) {
        localMerits = localMerits.filter(function (e) {
          return e !== merit.exclusive;
        });
      }

      //add to array
      localMerits.push(merit_id);
    }

    await setDoc(
      doc(
        db,
        "scorecard",
        user.scorecard,
        "players",
        user.id,
        "venues",
        venue.id
      ),
      {
        merits: localMerits,
      },
      {
        merge: true,
      }
    );
    venue.completedMerits = localMerits;
    venue.score = calculateVenueScore(venue);

    setVenues((prev) => prev.map((el) => (el.id === venue.id ? venue : el)));
  };

  const calculateVenueScore = (venue, rawMerits) => {
    const meritsData = rawMerits ? rawMerits : merits;
    const { challenges, completedChallenge, completedMerits } = venue;
    if (!completedChallenge) {
      return "TBD";
    }

    const meritScore = meritsData
      .filter((x) => completedMerits?.includes(x.id))
      .reduce((acc, merit) => acc + merit.score, 0);
    const challengeScore =
      challenges.find((x) => x.id === completedChallenge)?.score || 0;

    return challengeScore + meritScore;
  };

  const calculateTotalScores = (venues) => {
    return venues.reduce((acc, venue) => {
      return acc + (!isNaN(venue.score) ? parseInt(venue.score) : 0);
    }, 0);
  };

  const calculateHolesComplete = (venues) => {
    return venues.reduce((acc, venue) => {
      return acc + (venue.completedChallenge ? 1 : 0);
    }, 0);
  };

  const sortChallenges = (challenges) => {
    return challenges.sort((x, y) => y.score - x.score);
  };

  const sortMerits = (merits) => {
    return merits.sort((x, y) => x.number - y.number);
  };

  const sortVenues = (venues) => {
    return venues.sort((x, y) => x.number - y.number);
  };

  const formatScore = (score) => {
    return score === "TBD" ? "" : score > 0 ? `+${score}` : `${score}`;
  };

  const showError = (message) => {
    NotificationManager.error(message, null, 3000);
  };

  return (
    <Container>
      {loading && !showSpinner && <LoadingAnimation />}
      {loading && showSpinner && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {!loading && (
        <>
          {!showSpinner && (
            <Link to="/bingo">
              <Button className="btn-lg w-100 m-top" variant="primary">
                View Bingo Card
              </Button>
            </Link>
          )}
          <div className="m1 shadow">
            <Card className="pt-1 bg-light-grey">
              <Row className="m2 text-centre">
                <Col>
                  <Card.Title>
                    Hole:
                    <br />
                    {holesComplete}/{venues.length}
                  </Card.Title>
                </Col>
                <Col>
                  <Card.Title>{user.name}</Card.Title>
                </Col>
                <Col>
                  <Card.Title>
                    Score:
                    <br />
                    {formatScore(totalScore)}
                  </Card.Title>
                </Col>
              </Row>
            </Card>
          </div>
          {venues?.map((venue) => (
            <div className="m1 shadow">
              <Venue
                key={venue.id}
                venue={venue}
                user={user}
                merits={merits}
                handleChallengeClick={
                  currentUser.id === user.id || currentUser.id === ADMIN_ID
                    ? handleChallengeClick
                    : () => {
                        showError("Not your scorecard mate");
                      }
                }
                handleMeritClick={
                  currentUser.id === user.id || currentUser.id === ADMIN_ID
                    ? handleMeritClick
                    : () => {
                        showError("Not your scorecard mate");
                      }
                }
                formatScore={formatScore}
              />
            </div>
          ))}
          <br />
          <br />
        </>
      )}
    </Container>
  );
};

export default Scorecard;
