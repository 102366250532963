/* eslint-disable */
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";

// thanks chatgpt
//Used to shuffle the bingo challenges, and group them by weight (difficulty)
const shuffleAndGroupByWeight = (data) => {
  // Step 1: Group objects by weight
  const groups = {
    1: [],
    2: [],
    3: [],
    4: [],
  };

  data.forEach((item) => {
    if (!item.weight) {
      item.weight = 1; //default weight to 1
    }
    if (groups[item.weight]) {
      groups[item.weight].push(item);
    }
  });

  // Step 2: Shuffle each group
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  // Shuffle each group individually and store them back in the object
  return {
    1: shuffle(groups[1]),
    2: shuffle(groups[2]),
    3: shuffle(groups[3]),
    4: shuffle(groups[4]),
  };
};

//Gets the bingo challenges, shuffles them, then puts them into correct
//positions based on weight
export const generateBingoCard = async (scorecardId) => {
  let bingoChallenges = [];
  const bingoQuery = query(collection(db, "scorecard", scorecardId, "bingo"));
  const bingoSnapshot = await getDocs(bingoQuery);
  bingoSnapshot.forEach((doc) => {
    bingoChallenges.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  let bingoChallengesByWeight = shuffleAndGroupByWeight(bingoChallenges);

  //bingo card will be an array 0-24
  //this dict represents which difficulty (weight) should be in each position
  const positionByWeight = {
    1: [1, 3, 5, 9, 15, 19, 21, 23],
    2: [2, 6, 7, 8, 10, 14, 16, 17, 18, 22],
    3: [0, 4, 11, 13, 20, 24],
    4: [12],
  };
  // Step 1: Create an array of 25 elements initialized with undefined
  const bingoCard = Array(25).fill(undefined);

  let coinCount = 0;
  let findingCount = 0;
  let strangerCount = 0;
  // Step 2: Iterate over positionByWeight and fill the array
  Object.keys(positionByWeight).forEach((key) => {
    const positions = positionByWeight[key];
    positions.forEach((position) => {
      //we need to make sure we don't add more than 1 coin challenge,
      //3 findings, or 3 stranger challenges
      //yeah, this favours lower weighted challenges, but i don't care
      let selectedChallenge = bingoChallengesByWeight[key].shift(); //shift to remove first item, ensuring uniqueness
      let valid = !(
        (coinCount >= 1 && selectedChallenge.coin) ||
        (findingCount >= 3 && selectedChallenge.finding) ||
        (strangerCount >= 3 && selectedChallenge.stranger)
      );
      let retryCount = 0;
      while(!valid){
        selectedChallenge = bingoChallengesByWeight[key].shift();
        valid = !(
          (coinCount >= 1 && selectedChallenge?.coin) ||
          (findingCount >= 3 && selectedChallenge?.finding) ||
          (strangerCount >= 3 && selectedChallenge?.stranger)
        ) || retryCount > 10;
        retryCount++;
      }

      if(selectedChallenge?.coin) coinCount++;
      if(selectedChallenge?.finding) findingCount++;
      if(selectedChallenge?.stranger) strangerCount++;

      //then just add the challenge
      bingoCard[position] = {
        ...selectedChallenge,
        orderNumber: position,
      };
    });
  });
  return bingoCard;
};

export const calculateBingoScore = (challenges) => {
  const bingoLines = [
    // Rows
    [ 0, 1, 2, 3, 4 ],
    [ 5, 6, 7, 8, 9 ],
    [ 10, 11, 12, 13, 14 ],
    [ 15, 16, 17, 18, 19 ],
    [ 20, 21, 22, 23, 24 ],
    // Columns
    [ 0, 5, 10, 15, 20 ],
    [ 1, 6, 11, 16, 21 ],
    [ 2, 7, 12, 17, 22 ],
    [ 3, 8, 13, 18, 23 ],
    [ 4, 9, 14, 19, 24 ],
    // Diagonals
    [ 0, 6, 12, 18, 24 ],
    [ 4, 8, 12, 16, 20 ]
  ];

  return bingoLines.reduce((count, line) => {
    const lineCompleted = line.every(index => challenges[index]?.completed ?? false);
    return lineCompleted ? count - 2 : count;
  }, 0);
};
