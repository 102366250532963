/* eslint-disable */
import "../App.css";
import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { UserContext } from "../contexts/User/User";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import Scorecard from "./Scorecard";
import BingoCard from "./BingoCard";
import LoadingAnimation from "../components/LoadingAnimation";
import Notice from "../components/Notice";
const ADMIN_ID = "aVidi38mKdt9tHKPuiA5";

const Leaderboard = ({ user }) => {
  const [currentUser] = React.useContext(UserContext);
  const [players, setPlayers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [viewAs, setViewAs] = React.useState();
  const viewAsRef = React.useRef(null);

  React.useEffect(() => {
    const fetchPost = async () => {
      const playersSnapshot = await getDocs(
        collection(db, "scorecard", user.scorecard, "players")
      );
      const playersData = playersSnapshot.docs.map((player) => {
        return {
          id: player.id,
          ...player.data(),
          totalScore: player.data().bingoScore + player.data().golfScore,
        };
      });

      setPlayers(sortPlayers(playersData));
      setLoading(false);
    };

    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortPlayers = (players) => {
    return players.sort((x, y) => {
      if(["Bean", "Frida"].includes(y.name)){
        return 1;
      } else if (["Bean", "Frida"].includes(x.name)){
        return -1;
      }
      return x.totalScore - y.totalScore;
    });
    return players.sort((x, y) => x.totalScore - y.totalScore);
  };

  const formatScore = (score) => {
    return score > 0 ? `+${score}` : `${score}`;
  };

  const handleViewScorecardClick = (user_id, user_name) => {
    setViewAs({
      id: user_id,
      name: user_name,
      scorecard: user.scorecard,
      view: "scorecard",
    });
    setTimeout(() => {
      viewAsRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  const handleViewBingoClick = (user_id, user_name) => {
    setViewAs({
      id: user_id,
      name: user_name,
      scorecard: user.scorecard,
      view: "bingo",
    });
    setTimeout(() => {
      viewAsRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  const handleMakeOfficialClick = async (playerId, isOfficial) => {
    showError("Game has ended"); return;

    if (currentUser.id !== ADMIN_ID) {
      showError("Nice try bud");
      return;
    }

    await setDoc(
      doc(
        db,
        "scorecard",
        user.scorecard,
        "players",
        playerId,
      ),
      {
        official: !isOfficial,
      },
      {
        merge: true,
      }
    );

    setPlayers((prev) =>
      prev.map((el) => (el.id === playerId ? {...el, official: !isOfficial} : el))
    );
  };

  return (
    <>
      <Container>
        {loading && <LoadingAnimation />}
        {!loading && (

          <Card className="m15">
            <Notice/>

            <Card.Title className="text-centre m15">Leaderboard</Card.Title>
            <div className="simple-table">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan={2}>Name</th>
                    <th>Hole</th>
                    <th>Score</th>
                    <th>Card</th>
                    <th>Bingo</th>
                    {currentUser.id === ADMIN_ID && <th>Bingo</th>}
                  </tr>
                </thead>
                <tbody>
                  {players?.map((player) => {
                    if (player.id === ADMIN_ID || !player.name) {
                      return null;
                    }
                    return (
                      <tr key={`player_row_${player.id}`}>
                        <td colSpan={2}>{player.name}{["Bean","Frida"].includes(player.name) && " (Winner)"}{player.official && " (Official)"}</td>
                        <td className="text-end">{player.holesComplete}/9</td>
                        <td className="text-end">{formatScore(player.totalScore)}</td>
                        <td className="p-1">
                          <Button
                            className="btn-sm"
                            variant="primary"
                            onClick={() =>
                              handleViewScorecardClick(player.id, player.name)
                            }
                          >
                            View
                          </Button>
                        </td>
                        <td className="p-1">
                          <Button
                            className="btn-sm float-right"
                            variant="success"
                            onClick={() =>
                              handleViewBingoClick(player.id, player.name)
                            }
                          >
                            View
                          </Button>
                        </td>
                        {currentUser.id === ADMIN_ID &&
                          <td className="p-1">
                          <Button
                            className="btn-sm float-right"
                            variant={player.official ? "danger" : "primary"}
                            onClick={() =>
                              handleMakeOfficialClick(player.id, player.official)
                            }
                          >
                            Make {player.official && "Un"}Official
                          </Button>
                        </td>
                  }
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Card>
        )}
      </Container>

      {viewAs && (
        <div ref={viewAsRef}>
          {viewAs.view === "scorecard" && (
            <Scorecard user={viewAs} showSpinner={true} />
          )}
          {viewAs.view === "bingo" && (
            <BingoCard user={viewAs} showSpinner={true} />
          )}
        </div>
      )}
    </>
  );
};

export default Leaderboard;
