/* eslint-disable */
import "../App.css";
import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

const BingoRules = () => {
  return (
    <>
      <Container>
        <Card className="m15">
          <Card.Body>
            <Card.Title>Bingo Rules</Card.Title>
            <p>
              When a challenge has been completed, see an official to verify it
              either through photo/video evidence or witnesses, then check it
              off
            </p>
            <p>
              For each 'bingo' a player achieves i.e. each line of 5 challenges
              completed (including diagonals), they will score an{" "}
              <b>additional -2 points</b>. Some individual challenges also have
              the option to score additional points (use scorecard 'Merits'
              section to track).
            </p>
            <p>
              <b>
                Challenges have been selected such that collaboration/extortion
                of other players is highly encouraged.
              </b>
            </p>
            <Card.Title>Notes</Card.Title>
            <h5>* #45 The Chuggernaut:</h5>
            <p>
              The first person to skull a drink at the first venue after tee-off
              is the Chuggernaut. The title can be taken off them by beating
              them in a skull off (must be the same drink and the challenger
              pays). The Chuggernaut cannot deny a challenge without forfeiting
              the title.
            </p>
            <p>
              The Chuggernaut can forfeit the title without the challenger
              needing to buy them a drink, however the challenger must always
              skull a full drink to become the new Chuggernaut.
            </p>
            <p>
              <b>
                The person who is the final chuggernaut i.e. whoever is the
                chuggernaut at Bernie's at 10pm gets -3.
              </b>
            </p>
            <p>
              If the chuggernaut is not in the final venue by 9.10pm or leaves
              pub golf, the title is up for grabs.
            </p>

            <h5>** #39 Item weight</h5>
            <p>See Karlo to submit an item</p>
            <p>
              Each item can only be submitted once eg if you submit a schooner
              glass, you cannot submit another. Multiple items can be submitted
              at once but if wrong none of that item type can be submitted again
            </p>

            <h5>*** #52 Christ the Redeemer</h5>
            <p>
              You can't be touching the drink vessel with your hands or arms
              (creativity encouraged) and your arms must be out not touching
              your body Must finish half a drink at least
            </p>

            <h5>**** #46 Haircut (one of the following)</h5>
            <ul>
              <li>
                Alcohol haircut where somebody else pours a chaser in your mouth
                followed by a shot (must be spirit)
              </li>
              <li>
                Actual haircut of minimum fingernail length from min 20 adjacent
                strands of head hair through use of scissors by a stranger
              </li>
            </ul>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default BingoRules;
